import me from '../../images/me.png'
import './AboutSection.css'

const AboutSection = () => {
    return (
        <div className='about-section-container'>
            <div className="about-container">
                <img src={me} alt='Self Portrait' ></img>
                <div className='self-summary'>
                    <h3>
                        I am an Engineering industry veteran, now building software for the digital world. My passion for innovation and technology solves customer problems and transforms vision into reality.
                    </h3>
                    <h5>
                        I love to build things that can add value to other people's lives and in todays digital world, online is the place to do that. I'm an enthusiastic teammate and a patient communicator. I have a near-compulsive desire to explore the unfamiliar. My favorite languages are Python & JavaScript, I also have experience with C & C#.
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default AboutSection;
