import './ResumePage.css'

const ResumePage = () => {
  const pdfFilePath = './Bohmbach_Jordan_Resume.pdf';
  return (
      <div className='resume-page-container'>
          <iframe 
              title='Resume' 
              src={pdfFilePath}
          ></iframe>
      </div>
  );
};

export default ResumePage;
