import saidditLogo from '../../images/saiddit_logo.png'
import readupImage from '../../images/portfolio-readup.png'
import appOverloadImage from '../../images/portfolio-aa.png'
import quickMEPImage from '../../images/portfolio-quickelectrical.png'
import ddgXImage from '../../images/portfolio-ddgx.jpeg'
import botImage from '../../images/portfolio-robot.png'
import portfolioImage from '../../images/portfolio-portfolio-site.png'

export const portfolioInfo = [
    {
        brokenReason: 'This project is currently offline due to Free Heroku Postgres being deprecated.',
        detailsText: [
            'Saiddit is a full stack clone of reddit. Features include Users, Sub-Saiddits, Posts, and Comments. These can be Created, Read, Updated, or Deleted.',
            'The project was deployed using docker containers to Heroku.',
            'Data is stored in a PostgreSQL database and accessed via backend routes using the Flask framework.',
            'For Documentation on frontend and backend routes, please reference the project wiki.',
            'I am most proud of the Recursive Comment sections in this application which allow for infinitely nested and styled comments sections',
        ],
        filters: ['Any', 'Apps'],
        githubLink: 'https://github.com/jordan-bohmbach/saiddit',
        isWorking: true,
        link: 'https://saidd-it.herokuapp.com',
        name: 'Saiddit',
        popupImage: saidditLogo,
        projectStatus: 'Online',
        projectWiki: 'https://github.com/jordan-bohmbach/saiddit/wiki/API-Documentation',
        stacks: ['All', 'JavaScript', 'Python', 'Node.js', 'React.js', 'Redux', 'PostgreSQL', 'AWS', 'S3', 'Flask'],
        technologies: {
            'Frontend:': ['JavaScript', 'Node.js', 'React.js'],
            'Backend:': ['Python', 'Flask', 'AWS - S3', 'Alembic', 'Psycopg', 'PostgreSQL'],
            'Deployment:': ['Docker', 'Heroku'],
        },
        tileImage: saidditLogo,
    },
    {
        brokenReason: 'This project is currently offline due to Free Heroku Postgres being deprecated.',
        detailsText: [
            'Readup is a full stack clone of Meetup.com. Features include Users, Books, and Events. These can be Created, Read, Updated, or Deleted.',
            'The project was deployed using docker containers to Heroku.',
            'Data is stored in a PostgreSQL database and accessed via backend routes using the Flask framework.',
            'For Documentation on frontend and backend routes, please reference the project wiki.',
            'This was my first project done in React during the 24 week App Academy coding bootcamp',
        ],
        filters: ['Any', 'Apps'],
        githubLink: 'https://github.com/jordan-bohmbach/Readup-Meetup-Clone',
        isWorking: false,
        link: 'https://readup-aa.herokuapp.com',
        name: 'Readup',
        popupImage: readupImage,
        projectStatus: 'Temporarily Offline',
        projectWiki: 'https://github.com/jordan-bohmbach/Readup-Meetup-Clone/wiki/API-Documentation',
        stacks: ['All', 'JavaScript', 'Python', 'Node.js', 'React.js', 'PostgreSQL', 'Flask'],
        technologies: {
            'Frontend:': ['JavaScript', 'Node.js', 'React.js'],
            'Backend:': ['Python', 'Flask', 'Alembic', 'Psycopg', 'PostgreSQL'],
            'Deployment:': ['Docker', 'Heroku'],
        },
        tileImage: readupImage,
    },
    {
        brokenReason: 'This project is currently offline due to Free Heroku Postgres being deprecated.',
        detailsText: [
            'App Overload is a full stack clone of Stack Overflow. Features include Users, Questions, Answers, and Comments. These can be Created, Read, Updated, or Deleted.',
            'The project was deployed using docker containers to Heroku.',
            'Data is stored in a PostgreSQL database and accessed via backend JavaScript routes using the Express framework.',
            'For Documentation on frontend and backend routes, please reference the project wiki.',
            'The frontend for this is plain JavaScript manipulating HTML templates written in PUG',
        ],
        filters: ['Any', 'Apps'],
        githubLink: 'https://github.com/monemad/app-overload',
        isWorking: false,
        link: 'https://app-overload.herokuapp.com/',
        name: 'App Overload',
        popupImage: appOverloadImage,
        projectStatus: 'Temporarily Offline',
        projectWiki: 'https://github.com/monemad/app-overload/wiki/API-Documentation',
        stacks: ['All', 'JavaScript', 'Node.js', 'Express.js', 'PostgreSQL'],
        technologies: {
            'Frontend:': ['JavaScript', 'Node.js', 'PUG Templates'],
            'Backend:': ['JavaScrpt', 'Express', 'Sequelize', 'PostgreSQL'],
            'Deployment:': ['Docker', 'Heroku'],
        },
        tileImage: appOverloadImage,
    },
    {
        detailsText: [
            'QuickMEP was a startup building software for construction design engineers.',
            'The product was a plugin to AutoDesk Revit and AutoCAD.',
            'I wrote VBA for generating Excel reports and LISP for a prototype version of the product.',
            'The company was funded by venture capital group YCombinator'
        ],
        filters: ['Any', 'Entrepreneur', 'Engineering'],
        githubLink: '        N/A',
        isWorking: true,
        link: 'https://www.youtube.com/watch?v=71xtxSmPq2I&t=2s',
        name: 'QuickMEP',
        popupImage: quickMEPImage,
        projectStatus: 'Offline',
        projectWiki: '        N/A',
        stacks: ['All'],
        technologies: {
            'Frontend': ['Excel', 'VBA'],
            'Backend:': ['LISP'],
        },
        tileImage: quickMEPImage,
    },
//     {
//         detailsText: [
//             '"Bots" are scripts that automate repetitive processes on the Web which would normally require an employee.',
//             'These bots are written in JavaScript, using Node.js and deployed to run using a serverless architecture',
//             "The bots leverage google's Puppeteer library to automate the 'online paperwork' required to file health insurance claims",
//             'These 2 bots generate ~500 health insurance claims per night and have billed out over $20 Million of claims total since being put into production'
//         ],
//         filters: ['Any', 'Bots'],
//         githubLink: '        Private Repository',
//         isWorking: false,
//         link: '        This project is deployed to a private AWS account',
//         name: 'Claim Bots',
//         popupImage: botImage,
//         projectStatus: 'Online',
//         projectWiki: '        Private Wiki',
//         stacks: ['All', 'JavaScript', 'Node.js', 'AWS', 'Step Functions', 'Lambda Functions', 'RDS', 'S3'],
//         technologies: {
//             'Frontend:': ['Excel.js', 'AWS - SNS'],
//             'Backend:': ['JavaScrpt', 'Node.js', 'Puppeteer', 'PostgreSQL', 'AWS - Step Functions', 'AWS - Lambada Functions', 'AWS - RDS'],
//             'Deployment:': ['AWS - S3', 'AWS - Serverless Architecture'],
//         },
//         tileImage: botImage,
//     },
//     {
//         detailsText: [
//             '"Bots" are scripts that automate repetitive processes on the Web which would normally require an employee.',
//             'These bots are written in JavaScript, using Node.js and deployed to run using a serverless architecture',
//             "The bots leverage google's Puppeteer library to automate the 'online paperwork' required to audit billing entries per the customer's custom requirements",
//             'This bot has to log into several accounts and audit billing entries spanning many years. Since being deployed to production, it has audited over 200,000 billing entries representing over $34 Million of medical services'
//         ],
//         filters: ['Any', 'Bots'],
//         githubLink: '        Private Repository',
//         isWorking: false,
//         link: '        This project is deployed to a private AWS account',
//         name: 'Billing Entry Audit Bot',
//         popupImage: botImage,
//         projectStatus: 'Online',
//         projectWiki: '        Private Wiki',
//         stacks: ['All', 'JavaScript', 'Node.js', 'AWS', 'Step Functions', 'Lambda Functions', 'RDS', 'S3'],
//         technologies: {
//             'Frontend:': ['Excel.js', 'AWS - SNS'],
//             'Backend:': ['JavaScrpt', 'Node.js', 'Puppeteer', 'PostgreSQL', 'AWS - Step Functions', 'AWS - Lambada Functions', 'AWS - RDS'],
//             'Deployment:': ['AWS - S3', 'AWS - Serverless Architecture'],
//         },
//         tileImage: botImage,
//     },
//     {
//         detailsText: [
//             '"Bots" are scripts that automate repetitive processes on the Web which would normally require an employee.',
//             'These bots are written in JavaScript, using Node.js and deployed to run using a serverless architecture',
//             "The bots leverage google's Puppeteer library to automate the 'online paperwork' required to audit billing entries per the customer's custom requirements",
//             "This bot logs into the clients paylocity account. It scrapes information from this website and uses it to create PTO appointments for the client's employees as well as calculate their attainment for monthly performance bonuses"
//         ],
//         filters: ['Any', 'Bots'],
//         githubLink: '        Private Repository',
//         isWorking: false,
//         link: '        This project is deployed to a private AWS account',
//         name: 'Paylocity Bot',
//         popupImage: botImage,
//         projectStatus: 'Online',
//         projectWiki: '        Private Wiki',
//         stacks: ['All', 'JavaScript', 'Node.js', 'AWS', 'Step Functions', 'Lambda Functions', 'RDS', 'S3'],
//         technologies: {
//             'Frontend:': ['Excel.js', 'AWS - SNS'],
//             'Backend:': ['JavaScrpt', 'Node.js', 'Puppeteer', 'PostgreSQL', 'AWS - Step Functions', 'AWS - Lambada Functions', 'AWS - RDS'],
//             'Deployment:': ['AWS - S3', 'AWS - Serverless Architecture'],
//         },
//         tileImage: botImage,
//     },
//     {
//         detailsText: [
//             '"Bots" are scripts that automate repetitive processes on the Web which would normally require an employee.',
//             'These bots are written in JavaScript, using Node.js and deployed to run using a serverless architecture',
//             "The bots leverage google's Puppeteer library to automate the 'online paperwork' required to audit billing entries per the customer's custom requirements",
//             "This bot downloads template files off of the client's Microsoft Sharepoint site, as well as logging into the client's Salesforce account. It uses a combination of the information from these two sources to send emails to the client's customers with the availability for medical appointments in each region of the client's operations."
//         ],
//         filters: ['Any', 'Bots'],
//         githubLink: '        Private Repository',
//         isWorking: false,
//         link: '        This project is deployed to a private AWS account',
//         name: 'Regional Availability Bot',
//         popupImage: botImage,
//         projectStatus: 'Online',
//         projectWiki: '        Private Wiki',
//         stacks: ['All', 'JavaScript', 'Node.js', 'AWS', 'Step Functions', 'Lambda Functions', 'RDS', 'S3'],
//         technologies: {
//             'Frontend:': ['Excel.js', 'AWS - SNS'],
//             'Backend:': ['JavaScrpt', 'Node.js', 'Puppeteer', 'PostgreSQL', 'AWS - Step Functions', 'AWS - Lambada Functions', 'AWS - RDS'],
//             'Deployment:': ['AWS - S3', 'AWS - Serverless Architecture'],
//         },
//         tileImage: botImage,
//     },
//     {
//         detailsText: [
//             "I made this site as a catalog to keep track of projects that I have worked on in my professional career as a Software developer since graduating from App Academy in 2021",
//             "This site is relaviely simple and does not require a backend. It is built with React in the frontend and styled with CSS. I have also used components such as pre-styled buttons from the Material UI React Component library.",
//             "Despite the premade elements, there was a lot of custom CSS required to make the site look the way it does today"
//         ],
//         filters: ['Any', 'Apps', 'Entrepreneur'],
//         githubLink: '        Private Repository',
//         isWorking: true,
//         link: 'https://www.jordanbohmbach.codes/',
//         name: 'Portfolio Site',
//         popupImage: portfolioImage,
//         projectStatus: 'Online',
//         projectWiki: '        Private Wiki',
//         stacks: ['All', 'JavaScript', 'Node.js', 'React.js'],
//         technologies: {
//             'Frontend:': ['JavaScript', 'Node.js', 'React.js', 'MUI', 'CSS'],
//             'Deployment:': ['Netlify'],
//         },
//         tileImage: portfolioImage,
//     },
]
