import { useState } from "react"
import { portfolioInfo } from "./portfolioList";
import './Portfolio.css'
import PortfolioButtons from "./PortfolioButton";
import FilterButtonRow from "./FilterButtonRow";

const Portfolio = () => {
    const [filterType, setFilterType] = useState('Tech Stack')
    const [filter, setFilter] = useState('Any');
    const [stack, setStack] = useState('All');

    const filterTypes = ['Tech Stack', 'Project Type']
    const portfolioTypes = ['Any', 'Apps', 'Bots', 'Entrepreneur', 'Engineering']
    const stackTypes = ['All', 'JavaScript', 'Python', 'Node.js', 'React.js', 'Express.js', 'Flask', 'PostgreSQL', 'AWS', 'S3', 'Step Functions', 'Lambda Functions', 'RDS']

    const getButtonDefinitionArrs = (arr) => {
        let i = 0
        let definitionArr = []
        while (i < arr.length) {
            let isSelected = false;
            if (arr[i] === filterType) isSelected = true;
            if (arr[i] === filter) isSelected = true;
            if (arr[i] === stack) isSelected = true;
            definitionArr.push([arr[i], isSelected ? 'contained' : 'outlined'])
            i++
        }
        return definitionArr
    }

    const generatedTypeDefinition = getButtonDefinitionArrs(filterTypes);
    const portfolioDefinitions = getButtonDefinitionArrs(portfolioTypes);
    const stackDefinitions = getButtonDefinitionArrs(stackTypes);

    return(
        <div className="portfolio-container">
            <h1>My Portfolio</h1>
            <div className="portfolio-buttons-container">
                <div className="filter-type-buttons-container">
                    <FilterButtonRow 
                        filterTypesDefintions={generatedTypeDefinition}
                        filterType={filterType}
                        setFilterType={setFilterType}
                        buttonType={'filterType'}
                    />
                </div>
                {filterType === 'Tech Stack' && <div className="filter-buttons-container">
                    <FilterButtonRow 
                        filterTypesDefintions={stackDefinitions}
                        stack={stack}
                        setStack={setStack}
                        buttonType={'stack'}
                    />
                </div>}
                {filterType === 'Project Type' && <div className="filter-buttons-container">
                    <FilterButtonRow 
                        filterTypesDefintions={portfolioDefinitions}
                        filter={filter}
                        setFilter={setFilter}
                        buttonType={'category'}
                    />
                </div>}
            </div>
            <div className="portfolio-tiles-container">
                <PortfolioButtons projects={portfolioInfo.filter(portfolioItem => {
                    if (filterType === 'Project Type') {
                        return portfolioItem.filters.includes(filter);
                    } else {
                        return portfolioItem.stacks.includes(stack);
                    }
                })}/>
            </div>
        </div>
    )
}

export default Portfolio;
