import '../Style/Style.css'
import Scroller from "../Scroller";
import './Splash.css'
import Portfolio from "../Portfolio";
import AboutSection from '../About Section';

const Splash = () => {
    return (
        <>
            <Scroller />
            <AboutSection />
            <Portfolio />
        </>
    )
}

export default Splash
