import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const FilterButtonRow = ({
    filterTypesDefintions,
    filterType, setFilterType,
    filter, setFilter,
    stack, setStack,
    buttonType
}) => {

const handleFilterSelection = (event) => {
    if (buttonType === 'filterType') {
        setFilterType(event.currentTarget.id)
    } else if (buttonType === 'stack') {
        setStack(event.currentTarget.id)
    } else {
        setFilter(event.currentTarget.id)
    }
}

  return (
    <Stack spacing={2} direction="row">
        {filterTypesDefintions.map(definition => {
            const [text, variant] = definition
            return (
                <Button variant={variant} onClick={handleFilterSelection} id={text} key={text}>
                    {text}
                </Button>
            )
        })}
    </Stack>
  );
}

export default FilterButtonRow;
