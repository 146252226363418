import { useState, useEffect, useRef } from "react";
import './Scroller.css'
import ScrollerBackground from '../../images/scroller_background.jpg'
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

const Scroller = () => {
//     const thingsIAm = ['jordan bohmbach', 'a software engineer', 'looking for a job'];
    const thingsIAm = ['jordan bohmbach', 'a software engineer'];
    const [string, setString] = useState('|');
    const history = useHistory();
    const [delay, setDelay] = useState(150)
    const [stringIndex, setStringIndex] = useState(0)
    const [letterIndex, setLetterIndex] = useState(1)
    const [adding, setAdding] = useState(true);

    useInterval(() => {
        let word = thingsIAm[stringIndex];
        setString((word.slice(0, letterIndex)+'|').toUpperCase())

        if (adding) {
            console.log(`adding to letterIndex, delay is ${delay}`)
            console.log(`letterIndex = ${letterIndex}`)
            setLetterIndex(()=>letterIndex + 1);
            console.log(`letterIndex = ${letterIndex}`)

        } else {
            console.log(`subtracting from letterIndex, delay is ${delay}, new letterIndex is ${letterIndex - 1}`)
            setLetterIndex(()=>letterIndex - 1)
        }

        if (letterIndex > word.length) {
            setDelay(50)
            setAdding(false)
        } 
        
        if(letterIndex <= 0) {
            setDelay(150)
            setAdding(true)
            setLetterIndex(1)
            setStringIndex((stringIndex + 1) % thingsIAm.length)
        }
    }, delay)

    const handleGetResume = () => {
        history.push('/resume');
    }

    return(
        <div className="scrolling-background-container"  style={{ backgroundImage: `url(${ScrollerBackground})` }}>
            <div className="scrolling-component-container">
                <h1>
                    I'M {string}
                </h1>
                <h3>
                    I am an Engineering industry veteran, now building software for the digital world.
                </h3>
                <div className="social-media-box">
                    <a href="https://github.com/jordan-bohmbach"><img className='tag' src="https://img.icons8.com/ios/50/000000/github--v1.png" alt="GithubImage" /></a>
                    <a href='https://www.linkedin.com/in/jordanbohmbach/'><img className='tag' src="https://img.icons8.com/ios/50/000000/linkedin.png" alt="LinkedinImage" /></a>
                    <a href='https://angel.co/u/jordan-bohmbach-1/'><img className='tag' src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/512/external-angelist-website-for-startups-and-job-seekers-looking-to-work-at-startups-logo-shadow-tal-revivo.png" alt="AngelListImage" /></a>
                </div>
            <Button variant={'outlined'} onClick={handleGetResume} id={'get-resume-button'}>
                {'Get a Resume'}
            </Button>
            </div>
        </div>
    )
}

export default Scroller;
