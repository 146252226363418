import './PulsingCircle.css'

const PulsingCircle = ({color}) => {
    const backgroundStyle = {'background-color':`${color}`}
    const borderStyle = {border: `1px solid ${color}`}
    return (
        <div class="pulsatingCircle">
            <span class="firstCircle" style={backgroundStyle}></span>
            <span class="secondCircle" style={borderStyle}></span>
            <span class="thirdCircle" style={borderStyle}></span>
        </div>
    )
}

export default PulsingCircle
