import { useHistory, useParams } from "react-router-dom";
import { portfolioInfo } from "../Portfolio/portfolioList";
import Button from '@mui/material/Button';

import './ProjectPage.css'
import PulsingCircle from "./PulsingCircle";

const ProjectPage = () => {
    const {projectUrl} = useParams()
    const filteredProjects = portfolioInfo.filter(project => {
        const computedUrl = project.name.replace(/[^A-Z0-9]/ig, "");
        return computedUrl === projectUrl
    })
    const projectObject = filteredProjects[0]
    const linkStyle = projectObject.isWorking ? {} : {
        color: 'currentColor',
        cursor: 'not-allowed',
        opacity: 0.5,
        'text-decoration': 'none',
    }
    const nonClickLinkStyle = {
        color: 'currentColor',
        cursor: 'not-allowed',
        opacity: 0.5,
        'text-decoration': 'none',
    };
    const colorMap = {
        'Online': 'green',
        'Temporarily Offline': 'orange',
        'Offline': 'red'
    }
    const history = useHistory()

    const handleBackButtonClick = (e) => {
        debugger
        console.log(history)
        debugger;
        if (history) history.goBack()
    }

    return (
        <div className='project-page-container'>
            <Button variant="contained" onClick={handleBackButtonClick} id={'go-back-button'}>
                Go Back
            </Button>
            <h1>
                {projectObject.name}
            </h1>
            <div className='project-image-container'>
                <img src={projectObject.popupImage} alt={projectObject.name}></img>
            </div>
            <div className='project-details-container'>
                <div className='project-links-container'>
                    <h2>
                        {`Project Status:    `}
                    </h2>
                    <div className='status-and-circle-container'>
                        {projectObject.projectStatus}
                        <PulsingCircle color={colorMap[projectObject.projectStatus]}/>
                    </div>
                    <h2>
                        {`Deployed Project:    `}
                    </h2>
                    {projectObject.isWorking 
                    ? <a 
                        href={projectObject.link}
                        style={linkStyle}
                        >
                            {projectObject.link.slice(8)}
                      </a>
                    : <p style={linkStyle}>
                            {projectObject.link.slice(8)}
                      </p>}
                    {!projectObject.isWorking && <p>{projectObject.brokenReason}</p>}
                    <h2>
                        {`Github Repository:    `}
                    </h2>
                        {projectObject.githubLink.includes('https://')
                        ? <a href={projectObject.githubLink}>{projectObject.githubLink.slice(8)}</a>   
                        : <p style={nonClickLinkStyle}>
                            {projectObject.githubLink}
                        </p>}
                    <h2>
                        {`Wiki:    `}
                    </h2>
                        {projectObject.projectWiki.includes('https://')
                        ? <a href={projectObject.projectWiki}>{projectObject.projectWiki.slice(8)}</a>
                        : <p style={nonClickLinkStyle}>
                            {projectObject.projectWiki}
                        </p>}
                </div>
                <div className='vertical-separator'></div>
                <div className='tech-stack-details-container'>
                    {Object.entries(projectObject.technologies).map(([category, technologyArr]) => {
                        return (
                            <>
                                <h2>{category}</h2>
                                {technologyArr.map(tech => {
                                    return (
                                        <li>{tech}</li>
                                    )
                                })}
                            </>
                        )
                    })}
                </div>
                <div className='vertical-separator'></div>
                <div className='project-description-container'>
                    <h2>Project Description:</h2>
                    {projectObject.detailsText.map(paragraph => {
                        return (
                            <p>{paragraph}</p>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ProjectPage;
